import {Component} from '@angular/core';
import {ApiService} from './shared/services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})

export class AppComponent  {

  public Data: ChartData[] = [];
  public newDate: string = "0";

  constructor(private apiService: ApiService) {
    this.Data = [
      {
        "plan": 25,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "1"
      },
      {
        "plan": 22,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
        "date": "2"
      },
      {
        "plan": 30,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "3"
      },
      {
        "plan": 30,
        "fact": [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
        "date": "4"
      },
      {
        "plan": 25,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "5"
      },
      {
        "plan": 22,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
        "date": "6"
      },
      {
        "plan": 30,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "7"
      },
      {
        "plan": 30,
        "fact": [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
        "date": "8"
      },
      {
        "plan": 25,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "9"
      },
      {
        "plan": 22,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
        "date": "10"
      },
      {
        "plan": 30,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "11"
      },
      {
        "plan": 30,
        "fact": [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
        "date": "12"
      },
      {
        "plan": 30,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "13"
      },
      {
        "plan": 30,
        "fact": [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
        "date": "14"
      },
      {
        "plan": 25,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "15"
      },
      {
        "plan": 22,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
        "date": "16"
      },
      {
        "plan": 30,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "17"
      },
      {
        "plan": 30,
        "fact": [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
        "date": "18"
      },
      {
        "plan": 25,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "19"
      },
      {
        "plan": 22,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
        "date": "20"
      },
      {
        "plan": 30,
        "fact": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        "date": "21"
      },
      {
        "plan": 30,
        "fact": [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
        "date": "22"
      }
    ];
    this.newDate = (Number(this.Data[this.Data.length-1].date)).toString();

    setInterval(() => {
      this.getData();
    }, 5000);
  }

  getData(){
    this.apiService.getTask(2)
      .subscribe(Response => {
        this.updateData(Number(Response)*20);
      });
  }

  updateData(n: number){
    this.newDate = (Number(this.Data[this.Data.length-1].date)+1).toString();

    let fact = [];
    for (let i = 1; i <= Math.floor(Math.random() * Math.floor(100)); i++) {
      fact.push(i)
    }

    this.Data.push({
      "plan": n,
      "fact": fact,
      "date":  this.newDate
    });
  }
}

interface ChartData{
  plan: number,
  fact: number[],
  date: string
}
